import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from '@remix-run/react'
import { type ErrorResponse } from '@remix-run/router'
import { getErrorMessage } from '~/utils/misc.ts'
import { buttonVariants } from '@rouvydev/web-components'
import { useTranslation } from 'react-i18next'
import { Gradient } from '~/components/gradients.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { Layout } from '~/components/layout.tsx'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p className="text-text-default">
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = error => (
    <p className="text-text-default">{getErrorMessage(error)}</p>
  ),
}: {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
  const error = useRouteError()
  const params = useParams()
  const { t } = useTranslation()

  if (typeof document !== 'undefined') {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return (
    <Layout>
      <div className="relative h-full">
        <Gradient />
        <div className="container relative flex h-full flex-col items-center justify-center gap-8 py-12">
          <h1 className="font-display text-display-48 uppercase italic text-text-default">
            {t('whoops')}
          </h1>
          {isRouteErrorResponse(error)
            ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
                error,
                params,
              })
            : unexpectedErrorHandler(error)}
          <p>
            <NormalizedLink
              to="/"
              className={buttonVariants({
                variant: 'alternative',
              })}
            >
              {t('homepage_link')}
            </NormalizedLink>
          </p>
        </div>
      </div>
    </Layout>
  )
}
