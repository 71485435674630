import { useRootLoaderData } from '~/root.tsx'
import { Icon } from '~/components/icon.tsx'
import { fallbackLng, languageNames, supportedLngs } from '~/utils/i18n.ts'
import type { ActionFunctionArgs } from '@remix-run/node'
import { redirect } from '@remix-run/node'
import { Form, useLocation, useNavigation } from '@remix-run/react'
import { userPrefsLocale } from '~/utils/i18next.server.ts'
import { Fragment, useEffect, useState } from 'react'
import { destructPath } from '~/utils/page-state.ts'
import { cn } from '~/utils/misc.ts'
import { Popover, Transition } from '@headlessui/react'

export async function action({ request }: ActionFunctionArgs) {
  const form = await request.formData()
  let locale = form.get('locale') as string
  const path = form.get('pathname') as string

  locale = locale === fallbackLng ? '' : locale

  const redirectUrl =
    !locale && !path
      ? '/'
      : `${locale ? '/' + locale : ''}${path ? '/' + path : ''}`

  return redirect(redirectUrl, {
    headers: {
      'Set-Cookie': await userPrefsLocale.serialize(
        supportedLngs.includes(locale) ? locale : fallbackLng,
      ),
    },
  })
}

export function LanguageSelector({ simple }: { simple?: boolean }) {
  const [isOpen, setIsOpen] = useState(false)
  const data = useRootLoaderData()
  const navigation = useNavigation()
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      navigation.formAction === '/resources/language-selector' &&
      isOpen &&
      navigation.state === 'loading' &&
      navigation.formMethod === 'POST'
    ) {
      setIsOpen(false)
    }
  }, [navigation, isOpen])

  const selectedLng = data?.locale && languageNames[data.locale]

  return (
    <Popover className="md:relative">
      {() => (
        <div className="flex h-full">
          <Popover.Button className="space-x-1 border-none bg-transparent align-middle text-text-muted hover:text-global-white focus:outline-none">
            <Icon className="h-6 w-6 text-text-default" name="globe" />
            {!simple && (
              <span className="select-none text-body-14 font-semibold text-text-default">
                {selectedLng}
              </span>
            )}
            <Icon
              className={cn(
                'h-6 w-6 text-text-default',
                isOpen && 'rotate-180 transform',
              )}
              name="arrow-down"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute left-0 right-0 top-20 z-[1001] overflow-hidden text-body-14 font-medium leading-4 md:left-auto md:right-0 md:top-16 md:min-w-[150px] md:origin-top-right">
              <div className="border-t border-border-secondary bg-background-subtle lg:border-none">
                {supportedLngs.map(lang => (
                  <Form
                    method="POST"
                    action="/resources/language-selector"
                    key={lang}
                  >
                    <input type="hidden" name="locale" value={lang} />
                    <input
                      type="hidden"
                      name="pathname"
                      value={destructPath(pathname).path}
                    />
                    <Popover.Button
                      name="_action"
                      value="change-locale"
                      type="submit"
                      className={cn(
                        'flex w-full items-center px-4 py-3 text-text-default hover:bg-background-inset',
                        lang === data?.locale && 'bg-background-inset',
                      )}
                    >
                      {languageNames[lang]}
                    </Popover.Button>
                  </Form>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  )
}
